<template>
  <DataTable :value="devices">
    <template #header>
      <h1 style="display: inline-block; margin-right: 20px">Devices</h1>
      <span v-if="isAlert()" class="pi pi-bell animated"></span>
      <ToggleButton
        v-model="audioActive"
        onLabel="Audio enabled"
        offLabel="Audio disabled"
        onIcon="pi pi-volume-up"
        offIcon="pi pi-volume-off"
        class="w-9rem audio-switch"
      />
    </template>
    <Column field="deviceId" header="Device ID"></Column>
    <Column header="Connection">
      <template #body="slotProps">
        <span v-if="slotProps.data.connected" class="pi pi-wifi"></span>
        <span v-else class="pi pi-ban"></span>
      </template>
    </Column>
    <Column header="Alert">
      <template #body="slotProps">
        <span v-if="slotProps.data.status && isRecent(slotProps.data.lastUpdate)" class="pi pi-bell animated"></span>
        <span v-else-if="slotProps.data.status && !isRecent(slotProps.data.lastUpdate)" class="pi pi-bell"></span>
        <span v-else class="pi pi-check-circle"></span>
      </template>
    </Column>
    <Column field="battery" header="Battery"></Column>
    <Column header="Location">
      <template #body="slotProps">
        <a
          v-if="slotProps.data.connected"
          :href="geoLink(slotProps.data.latitude, slotProps.data.longitude)"
          target="_blank"
        >
          <span class="pi pi-map"></span>
        </a>
        <span v-else class="pi pi-question"></span>
      </template>
    </Column>
    <Column header="Last Update">
      <template #body="slotProps">
        {{ formatLastUpdate(slotProps.data.lastUpdate) }}
      </template>
    </Column>
  </DataTable>
  <audio id="myAudio">
    <source src="/alarm.wav" type="audio/wav" />
    Your browser does not support the audio element.
  </audio>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ToggleButton from "primevue/togglebutton";
export default {
  name: "App",
  components: {
    DataTable,
    Column,
    ToggleButton,
  },
  data: function () {
    return {
      audioActive: false,
      devices: [
/*
        {
          id: 1,
          deviceId: "68:B6:B3:2D:27:72",
          connected: true,
          status: true,
          battery: 79,
          latitude: "48.0999036",
          longitude: "11.5002416",
          lastUpdate: "2023-07-18T21:28:39+00:00",
        },
*/

      ],
    };
  },
  methods: {
    geoLink: function (latitude, longitude) {
      return (
        "https://www.google.com/maps/search/?api=1&query=" +
        latitude +
        "%2C" +
        longitude
      );
    },
    formatLastUpdate: function (dateTimeString) {
      const dateTime = new Date(dateTimeString);
      return dateTime.toLocaleString();
    },
    isRecent: function(dateTimeString) {
      const updateTime = new Date(dateTimeString);
      const currentTime = new Date();

      const diffMinutes = (currentTime.getTime() - updateTime.getTime()) / (1000 * 60);

      return diffMinutes < 16;
    },
    isAlert: function () {
      let isAlert = false;

      for (let i = 0; i < this.devices.length; i++) {
        const devConnected = this.devices[i].connected;
        const devStatus = this.devices[i].status;
        const isRecent = this.isRecent(this.devices[i].lastUpdate);

        if (devConnected && devStatus && isRecent) {
          isAlert = true;
        }
      }

      return isAlert;
    },
    audioCheck: function () {
      const isAlert = this.isAlert();
      if (this.audioActive && isAlert) {
        document.getElementById("myAudio").currentTime = 0;
        document.getElementById("myAudio").play();
      } else {
        document.getElementById("myAudio").pause();
      }
    },
    pullData: async function () {
      const response = await fetch("https://holster.balistic.ro/data");
      this.devices = await response.json();
    }
  },
  mounted() {
    setInterval(this.audioCheck, 3400);
    setInterval(this.pullData, 3000);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.audio-switch {
  position: absolute !important;
  right: 5px;
  top: 5px;
  background-color: purple !important;
}

.animated {
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes example {
  0% {
    color: white;
  }
  50% {
    color: red;
    transform: scale(1.6);
    font-weight: bold;
  }
  100% {
    color: white;
  }
}
</style>
